import {Component, h, Host} from '@stencil/core';

/** Utility class used to contain some static methods dealing with JSX */
// TODO: this should become just a .ts file or be included in utils
@Component({
  tag: 'generic-ui',
  styleUrl: 'generic-ui.css',
  shadow: true,
})
export class GenericUi {
  static getEditable(content, onEdit, className = null) {
    return <span class={className}>{content}&nbsp;&nbsp;&nbsp;
      <ion-icon name="create-outline" style={{cursor: "pointer"}} onClick={e => onEdit(e)}/>
      &nbsp;&nbsp;&nbsp;</span>
  }

  // Not intended to be used
  render() {
    return (
      <Host>
        <slot></slot>
      </Host>
    );
  }

}
